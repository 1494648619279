<template>
  <section id="pengajuan-daftar-surat">
    <b-row>
      <b-col
        lg="3"
        cols="12"
        order="2"
        order-lg="1"
      >
        <profile-about :master-id="userData.instansi_id" />
        <profile-side-menu />
      </b-col>

      <!-- post -->
      <b-col
        lg="9"
        cols="12"
        order="1"
        order-lg="2"
      >
        <b-card>
          <!-- search input -->
          <b-row class="justify-content-end mb-1">
            <b-col md="4">
              <b-input-group>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Search"
                  type="text"
                  @keyup.enter="tableSearch"
                />
                <b-input-group-append>
                  <b-button
                    v-if="searchTerm"
                    variant="outline-secondary"
                    class="p-50"
                    @click="searchReset"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      size="12"
                    />
                  </b-button>
                </b-input-group-append>
                <b-input-group-append>
                  <b-button
                    variant="outline-secondary"
                    @click="tableSearch"
                  >
                    <feather-icon
                      icon="SearchIcon"
                      size="12"
                    />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>

          <!-- table -->
          <vue-good-table
            mode="remote"
            :total-rows="totalRecords"
            :is-loading.sync="isLoading"
            :columns="columns"
            :rows="rows"
            :pagination-options="{
              enabled: true,
              perPage:serverParams.length
            }"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span v-if="props.column.field == 'tanggal_surat'">
                {{ props.row.tanggal_surat | formatDate }}
              </span>
              <div v-else-if="props.column.field == 'surat_id'">
                <template v-if="props.row.apps.length !== 0">
                  <h6>Aplikasi</h6>
                  <b-list-group
                    class="mb-1"
                    flush
                  >
                    <b-list-group-item
                      v-for="(data, index) in props.row.apps"
                      :key="index"
                      class="p-25"
                    >
                      {{ data.app_infra }}
                    </b-list-group-item>
                  </b-list-group>
                </template>
                <template v-if="props.row.infras.length !== 0">
                  <h6>Infrastruktur</h6>
                  <b-list-group
                    class="mb-1"
                    flush
                  >
                    <b-list-group-item
                      v-for="(data, index) in props.row.infras"
                      :key="index"
                      class="p-25"
                    >
                      {{ data.app_infra }}
                    </b-list-group-item>
                  </b-list-group>
                </template>
                <template v-if="props.row.datas.length !== 0">
                  <h6>Data</h6>
                  <b-list-group
                    class="mb-1"
                    flush
                  >
                    <b-list-group-item
                      v-for="(data, index) in props.row.datas"
                      :key="index"
                      class="p-25"
                    >
                      {{ data.app_infra }}
                    </b-list-group-item>
                  </b-list-group>
                </template>
                <template v-if="props.row.non_teknis.length !== 0">
                  <h6>Non Teknis</h6>
                  <b-list-group
                    class="mb-1"
                    flush
                  >
                    <b-list-group-item
                      v-for="(data, index) in props.row.non_teknis"
                      :key="index"
                      class="p-25"
                    >
                      {{ data.app_infra }}
                    </b-list-group-item>
                  </b-list-group>
                </template>
              </div>
              <div v-else-if="props.column.field == 'url_kominfo'">
                <template
                  v-if="props.row.kominfo_sign === 1 && props.row.panrb_sign === 1 && (userData.group_id === 100 || userData.group_id === 101)"
                >
                  <b-button
                    v-b-tooltip.hover
                    variant="info"
                    size="sm"
                    class="mr-25 mb-25"
                    title="View Surat Kop Kominfo"
                    @click="openFile(props.row.url_kominfo)"
                  >
                    <feather-icon
                      icon="FileIcon"
                      size="12"
                    />
                  </b-button>
                  <b-link
                    v-b-tooltip.hover
                    :href="getDoc(props.row.url_kominfo)"
                    class="btn btn-sm btn-success mr-25 mb-25"
                    target="_blank"
                    title="Download Surat Kominfo"
                  >
                    <feather-icon
                      icon="DownloadCloudIcon"
                      size="12"
                    />
                  </b-link>
                </template>
                <b-button
                  v-else-if="userData.group_id === 100"
                  variant="outline-secondary"
                  size="sm"
                >Menunggu TTE Eselon 1
                </b-button>
              </div>
              <div v-else-if="props.column.field == 'url_panrb'">
                <template
                  v-if="props.row.kominfo_sign === 1 && props.row.panrb_sign === 1 && (userData.group_id === 100 || userData.group_id === 101)"
                >
                  <b-button
                    v-b-tooltip.hover
                    variant="info"
                    size="sm"
                    class="mr-25 mb-25"
                    title="View Surat Kop MenpanRB"
                    @click="openFile(props.row.url_panrb)"
                  >
                    <feather-icon
                      icon="FileIcon"
                      size="12"
                    />
                  </b-button>
                  <b-link
                    v-b-tooltip.hover
                    :href="getDoc(props.row.url_panrb)"
                    class="btn btn-sm btn-success mr-25 mb-25"
                    target="_blank"
                    title="Download Surat MenpanRB"
                  >
                    <feather-icon
                      icon="DownloadCloudIcon"
                      size="12"
                    />
                  </b-link>
                </template>
                <b-button
                  v-else-if="userData.group_id === 100"
                  variant="outline-secondary"
                  size="sm"
                >Menunggu TTE Eselon 1
                </b-button>
              </div>
              <div v-else-if="props.column.field == 'url_bapenas'">
                <template
                  v-if="props.row.kominfo_sign === 1 && props.row.panrb_sign === 1 && props.row.bapenas_sign === 1 && (userData.group_id === 100 || userData.group_id === 101)"
                >
                  <b-button
                    v-b-tooltip.hover
                    variant="info"
                    size="sm"
                    class="mr-25 mb-25"
                    title="View Surat Kop Bappenas"
                    @click="openFile(props.row.url_bapenas)"
                  >
                    <feather-icon
                      icon="FileIcon"
                      size="12"
                    />
                  </b-button>
                  <b-link
                    v-b-tooltip.hover
                    :href="getDoc(props.row.url_bapenas)"
                    class="btn btn-sm btn-success mr-25 mb-25"
                    target="_blank"
                    title="Download Surat Bappenas"
                  >
                    <feather-icon
                      icon="DownloadCloudIcon"
                      size="12"
                    />
                  </b-link>
                </template>
                <b-button
                  v-else-if="userData.group_id === 100"
                  variant="outline-secondary"
                  size="sm"
                >Menunggu TTE Eselon 1
                </b-button>
              </div>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="serverParams.length"
                    :options="['10','20','50']"
                    class="mx-1"
                    @change="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    v-model="selectPage"
                    :total-rows="props.total"
                    :per-page="serverParams.length"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @change="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>

      </b-col>
      <!-- post -->
    </b-row>

    <b-modal
      id="modal-komponen"
      ref="modal-komponen"
      hide-footer
      centered
      scrollable
      size="lg"
    >
      <b-card-text>
        <!-- search input -->
        <vue-pdf-embed
          :source="fileSurat"
          style="height: 90vh;"
        />
      </b-card-text>
    </b-modal>

  </section>

</template>

<script>
import {
  BRow, BButton, BModal, BCol, BCard, BPagination, BInputGroup, BInputGroupAppend, BLink,
  BFormInput, BFormSelect, VBTooltip, VBModal, BListGroup, BListGroupItem,
} from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

import stColors from '@/vars/statusColors'
import tableColumns from '@/vars/ippdSuratColumns'
import ProfileAbout from './module/PengajuanAbout.vue'
import ProfileSideMenu from './module/PengajuanSideMenu.vue'

import 'vue-pdf-app/dist/icons/main.css'

export default {
  components: {
    BButton,
    BCard,
    BModal,
    BRow,
    BCol,
    BLink,
    VueGoodTable,
    BListGroup,
    BListGroupItem,
    BPagination,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    VuePdfEmbed,
    ProfileAbout,
    ProfileSideMenu,
  },
  setup() {
    const { statusColors } = stColors()
    const { newColumns } = tableColumns()

    const yearBudget = Number(localStorage.getItem('tahunAnggaran'))
    const columns = newColumns

    return {
      yearBudget,
      statusColors,
      columns,
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  beforeRouteEnter(to, from, next) {
    const role = Number(localStorage.getItem('roleId'))
    if (role === '10') {
      next('/')
    } else {
      next()
    }
  },
  data() {
    return {
      fileSurat: '',
      userData: getUserData(),
      isLoading: false,
      totalRecords: 0,
      selectPage: 1,
      serverParams: {
        tahun_anggaran: localStorage.getItem('tahunAnggaran'),
        sort_by: [
          {
            column: 'letter_number',
            asc_desc: 'asc',
          },
        ],
        start: 0,
        length: 10,
      },
      rows: [],
      searchTerm: '',
    }
  },
  mounted() {
    this.loadItems()
  },
  methods: {
    openFile(value) {
      this.fileSurat = `${process.env.VUE_APP_API_URL}${value}?token=${localStorage.getItem('userToken')}`
      this.$refs['modal-komponen'].show()
    },
    getDoc(data) {
      return `${process.env.VUE_APP_API_URL}${data}?token=${localStorage.getItem('userToken')}`
    },
    tableSearch() {
      this.updateParams({ keyword: this.searchTerm })
      this.loadItems()
    },
    searchReset() {
      this.searchTerm = ''
      this.updateParams({ keyword: '' })
      this.loadItems()
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    onPageChange(params) {
      this.selectPage = params.currentPage
      this.updateParams({ start: (params.currentPage - 1) * this.serverParams.length })
      this.loadItems()
    },
    onPerPageChange(params) {
      this.updateParams({ length: params.currentPerPage })
      this.loadItems()
    },
    onSortChange(params) {
      params.map(param => {
        this.updateParams({
          sort_by: [{
            asc_desc: param.type,
            column: param.field,
          }],
        })
        return true
      })
      this.loadItems()
    },
    onColumnFilter(params) {
      const filterz = []
      Object.entries(params.columnFilters).forEach(entry => {
        const [key, valuez] = entry
        filterz.push({
          column: key,
          value: valuez,
        })
      })
      this.updateParams({
        filters: filterz,
      })
      this.loadItems()
    },
    loadItems() {
      this.rows = []
      this.$http.post(`/surat/list-surat-rekomendasi?token=${localStorage.getItem('userToken')}`, this.serverParams)
        .then(res => {
          if (res.data.status === 'success') {
            this.rows = res.data.data.data
            this.totalRecords = res.data.data.total_rows
          }
        })
    },
    statusVariant(status) {
      if (this.statusColors[status]) {
        return this.statusColors[status]
      }
      return {
        title: status,
        class: 'light-success',
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
